import { DzTitlePage as DzTitlePageBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzTitlePageProps = ComponentProps<typeof DzTitlePageBasic>
type Props = Omit<DzTitlePageProps, 'LinkElement'>

export const DzTitlePage = (props: Props) => {
  const dzTitlePageProps = { ...props, LinkElement: Link } as DzTitlePageProps
  return <DzTitlePageBasic {...dzTitlePageProps} />
}

DzTitlePage.displayName = 'DzTitlePageWrapper'
