import { DzList as DzListBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzListProps = ComponentProps<typeof DzListBasic>
type Props = Omit<DzListProps, 'LinkElement'>

export const DzList = (props: Props) => {
  const dzListProps = { ...props, LinkElement: Link } as DzListProps
  return <DzListBasic {...dzListProps} />
}

DzList.displayName = 'DzListWrapper'
