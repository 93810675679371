import { groq } from 'next-sanity'
import { z } from 'zod'

import {
  pageSEOFields,
  PageSEOFieldsExtendedSchema,
} from '@/sanity/queries/components/seo/pageSEOFields'
import {
  pageBuilderComponentsData,
  PageBuilderComponentsDataSchema,
  pageBuilderDataTypes,
} from '@/sanity/queries/page/pageCommonQueries/pageBuilderComponentsData'

export const hongKongLandingStructure = groq`*[_type == "hongkong" ][0] {
  contentAboveArtistListing[]{
    ${pageBuilderDataTypes}
  },
  contentBelowArtistListing[]{
    ${pageBuilderDataTypes}
  },
}`

export const getHongKongLandingQuery = (content: string) => groq`*[_type == "hongkong" ][0] {
  _type,
  title,
  "seo": {
    title,
    'canonical': coalesce(slug.current, null),
    ...seo {
    ${pageSEOFields}
    }
  },
  "artistPages":*[_type == "artistPage" && defined(slug.current) && artist->.affiliation == true] {
    "slug": slug.current,
    artist-> {
      fullName,
      chineseName,
      lastName,
      firstName
    }
  },
  ${content}
}`

export const hongKongLandingData = groq`*[_type == "hongkong" ][0] {
  _type,
  title,
  "seo": {
    title,
    'canonical': coalesce(slug.current, null),
    ...seo {
    ${pageSEOFields}
    }
  },
  contentAboveArtistListing[]{
    ${pageBuilderComponentsData}
  },
  contentBelowArtistListing[]{
    ${pageBuilderComponentsData}
  },
  "artistPages":*[_type == "artistPage" && defined(slug.current) && artist->.affiliation == true] {
    "slug": slug.current,
    artist-> {
      fullName,
      chineseName,
      lastName,
      firstName
    }
  }
}`

export const HongKongLandingDataSchema = z.object({
  _type: z.literal('hongkong'),
  title: z.string(),
  seo: PageSEOFieldsExtendedSchema,
  contentAboveArtistListing: z.array(PageBuilderComponentsDataSchema).nullable(),
  contentBelowArtistListing: z.array(PageBuilderComponentsDataSchema).nullable(),
  artistPages: z
    .array(
      z.object({
        slug: z.string(),
        artist: z.object({
          firstName: z.string().nullable(),
          lastName: z.string(),
          fullName: z.string(),
          chineseName: z.string().nullable(),
        }),
      })
    )
    .nullable(),
})

export type HongKongLandingDataType = z.infer<typeof HongKongLandingDataSchema>
