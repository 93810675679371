import { DzColumn, DzTitleMoleculeTypes } from '@zwirner/design-system'

import { FullWidthFlexCol } from '@/components/containers/layout/FullWidthFlexCol'
import PageBuilder, { showPageBuilderSection } from '@/components/pageBuilder'
import { DzList } from '@/components/wrappers/DzListWrapper'
import { DzTitleMolecule } from '@/components/wrappers/DzTitleMoleculeWrapper'
import { DzTitlePage } from '@/components/wrappers/DzTitlePageWrapper'
import type { HongKongLandingDataType } from '@/sanity/queries/hongkong/hongkongPage'

type HongKongContainerProps = {
  data: HongKongLandingDataType
}

export const HongKongContainer = ({ data }: HongKongContainerProps) => {
  const { title, artistPages } = data ?? {}

  return (
    <DzColumn span={12}>
      <DzTitlePage title={title} isWide />
      <FullWidthFlexCol>
        {showPageBuilderSection(data.contentAboveArtistListing) ? (
          <PageBuilder components={data.contentAboveArtistListing} />
        ) : null}

        <div>
          <DzTitleMolecule
            type={DzTitleMoleculeTypes.SECTION}
            data={{
              title: '藝術家/Artists',
              customClass: 'mb-10 md:mb-[3.75rem]',
            }}
          />
          <DzList
            list={
              artistPages?.map((artistPage) => ({
                text: artistPage.artist.chineseName || artistPage.artist.fullName,
                lastName: artistPage.artist.lastName,
                firstName: artistPage.artist?.firstName || '',
                url: artistPage.slug,
              })) || []
            }
            sort
            alphabetProps={{
              disabled: true,
            }}
          />
        </div>
        {showPageBuilderSection(data.contentBelowArtistListing) ? (
          <PageBuilder components={data.contentBelowArtistListing} />
        ) : null}
      </FullWidthFlexCol>
    </DzColumn>
  )
}
